import React from "react"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import { useTheme } from "styled-components"
import Container from "@components/layout/container"
import Flex from "@components/elements/flex"
import { graphql } from "gatsby"
import Paragraph from "@components/elements/paragraph"
import Div from "@components/elements/div"
import YouTubeEmbed from "@components/directus/youtube-embed"
import SignUpCTA from "@components/cta/sign-up-cta"
import Image from "@components/image"
import ImageWrapper from "@components/directus/image-wrapper"
import { WideColourfulCTA } from "@components/cta/colourful/wide"

const ReferencePage = ({ data }) => {
  const { color } = useTheme()

  return (
    <Layout
      title="Reference"
      description={`Internal reference page for design components.`}
      seo={{
        tracking: ["noindex", "nofollow", "noarchive"],
      }}
    >
      <Container text>
        <Flex gap="2rem">
          <div>
            <Paragraph>Featured snippet:</Paragraph>
            <Div id="featured-snippet">Snippet</Div>
          </div>

          <div>
            <Paragraph>Featured snippet (styled):</Paragraph>
            <Flex
              id="featured-snippet"
              padding="2rem"
              gap="2rem"
              margin="0 0 2rem"
              borderRadius="1rem"
              background={color.indigo100}
            >
              Snippet
            </Flex>
          </div>

          <div>
            <Paragraph>YouTube embed code:</Paragraph>
            <YouTubeEmbed id={"tBFrRaEeo8Q"} />
          </div>

          <div>
            <Paragraph>Sign Up CTA (Keyword: &quot;Test&quot;)</Paragraph>
            <Div margin="0 0 2rem" maxWidth="450px">
              <SignUpCTA category={"Test"} />
            </Div>
          </div>

          <div>
            <Paragraph>Article CTA</Paragraph>
            <WideColourfulCTA />
          </div>

          <div>
            <Paragraph>Image Wrapper</Paragraph>
            <ImageWrapper shadow={false}>
              <Image file={data.image} />
            </ImageWrapper>
          </div>

          <div>
            <Paragraph>Image Wrapper (Shadow)</Paragraph>
            <ImageWrapper>
              <Image file={data.image} />
            </ImageWrapper>
          </div>
        </Flex>
      </Container>
    </Layout>
  )
}

ReferencePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ReferencePage

export const pageQuery = graphql`
  query RequestDemoQuery {
    image: directusFiles(
      directus_id: { eq: "2d566dc7-da07-4e0e-b8f4-d07951b483db" }
    ) {
      cdn
      id
      placeholder
      title
    }
  }
`
